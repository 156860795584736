import { useContract } from "./useContract";
import { ApplicantNodeStatus } from "./useFetchApplicationMe/Applicant";
import { ContractType } from "./useFetchApplicationMe/ApplicationRequest";
import { useFetchedApplicant } from "./useFetchedApplicant";
import { useIsPrimaryApplicant } from "./useIsPrimaryApplicant";

export function useRequireNegotiation(): boolean {
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const { type } = useContract();
  return [ContractType.GUARANTEE].includes(type) && isPrimaryApplicant;
}

export function useNeedLeaseStartDate(): boolean {
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const { type } = useContract();
  const applicantNode = useFetchedApplicant();
  console.log("applicantNode", applicantNode);
  return (
    [ContractType.GUARANTEE, ContractType.TRADITIONAL, ContractType.FLEX].includes(type) &&
    isPrimaryApplicant &&
    applicantNode.status === ApplicantNodeStatus.NEW
  );
}
